@font-face {
  font-family: "AvenirBook";
  src: url("#{$font-directory}AvenirLTStd-Book.eot"); /* IE9 Compat Modes */
  src: url("#{$font-directory}AvenirLTStd-Book.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-directory}AvenirLTStd-Book.svg") format("svg"),
    /* Legacy iOS */ url("#{$font-directory}AvenirLTStd-Book.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("#{$font-directory}AvenirLTStd-Book.woff")
      format("woff"),
    /* Modern Browsers */ url("#{$font-directory}AvenirLTStd-Book.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirBlack";
  src: url("#{$font-directory}AvenirLTStd-Black.eot"); /* IE9 Compat Modes */
  src: url("#{$font-directory}AvenirLTStd-Black.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-directory}AvenirLTStd-Black.otf")
      format("opentype"),
    /* Open Type Font */ url("#{$font-directory}AvenirLTStd-Black.svg")
      format("svg"),
    /* Legacy iOS */ url("#{$font-directory}AvenirLTStd-Black.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("#{$font-directory}AvenirLTStd-Black.woff")
      format("woff"),
    /* Modern Browsers */ url("#{$font-directory}AvenirLTStd-Black.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
