@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  position: relative;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper .ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.com-baforms-wrapper .ba-form-page fieldset {
  padding: 0;
  border: none;
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.com-baforms-wrapper .ba-form-page .ba-form-page-navigation-title,
.com-baforms-wrapper .ba-form-page .upload-file-btn,
.com-baforms-wrapper .ba-form-page .upload-file-input,
.com-baforms-wrapper .ba-form-page .ba-field-container select,
.com-baforms-wrapper .ba-form-page .ba-field-container textarea[readonly],
.com-baforms-wrapper .ba-form-page .ba-field-container textarea,
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=text],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=number],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=password],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=email],
.com-baforms-wrapper .ba-form-page .ba-field-container select option,
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: normal;
  font-size: 1rem !important;
  color: #194894 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #ffffff;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #f5b04f;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #edf0ef;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: Helvetica, sans-serif;
  font-size: 100%;
  line-height: 1.5;
  color: #194894;
  font-weight: normal;
  background: #ffffff;
}

main,
details {
  display: block;
}

p,
li {
  margin-bottom: 1rem;
}

a {
  color: #f5b04f;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
a:hover {
  color: #194894;
}

ul,
ol {
  margin-left: 2rem;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  width: 100%;
  font-family: Helvetica, sans-serif;
  line-height: 120%;
  font-weight: normal;
  margin: 0 0 1rem;
  text-transform: uppercase;
  color: #194894;
}

h1,
.h1 {
  font-size: 3.4rem;
  font-family: "AvenirBlack", sans-serif;
}

h2,
.h2 {
  font-size: 2.2rem;
}

h3,
.h3 {
  font-size: 1.7rem;
}

h4,
.h4 {
  font-size: 1.6rem;
}

h5,
.h5 {
  font-size: 1.4rem;
}

h6,
.h6 {
  font-size: 1.4rem;
}

.button,
button,
input[type=submit],
input[type=reset],
input[type=button] {
  background: #194894;
  border: 0;
  color: #ffffff;
  display: inline-block;
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20px;
  line-height: normal;
  padding: 0.7em 1.5em;
  font-family: Helvetica, sans-serif;
  text-transform: uppercase;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover {
  color: #ffffff;
  background: #2468d5;
  border: 0;
}
.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active {
  border: 0;
}

.button--secondary {
  background: none;
  border: 2px solid #194894;
  color: #194894;
}
.button--secondary:hover {
  background: #194894;
  border: 2px solid #194894;
  color: #ffffff;
}

.button--calendar {
  padding: 0.5em 0.8em 0.5em 2em;
  background-image: url("/images/misc/calendar.gif");
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: 0.5em 0.55em;
}
.button--calendar:hover {
  background-image: url("/images/misc/calendar.gif");
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: 0.5em 0.55em;
  background-color: #2468d5;
}

footer .button--calendar {
  padding: 0.7em 1.5em 0.7em 2em;
  border: 2px solid #194894;
  background-position: 0.5em 0.65em;
}
footer .button--calendar:hover {
  border: 2px solid #2468d5;
  background-position: 0.5em 0.65em;
}

.button.full-width,
button.full-width,
input[type=submit].full-width,
input[type=reset].full-width,
input[type=button].full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@font-face {
  font-family: "AvenirBook";
  src: url("../fonts/AvenirLTStd-Book.eot"); /* IE9 Compat Modes */
  src: url("../fonts/AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirLTStd-Book.svg") format("svg"), url("../fonts/AvenirLTStd-Book.ttf") format("truetype"), url("../fonts/AvenirLTStd-Book.woff") format("woff"), url("../fonts/AvenirLTStd-Book.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AvenirBlack";
  src: url("../fonts/AvenirLTStd-Black.eot"); /* IE9 Compat Modes */
  src: url("../fonts/AvenirLTStd-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/AvenirLTStd-Black.otf") format("opentype"), url("../fonts/AvenirLTStd-Black.svg") format("svg"), url("../fonts/AvenirLTStd-Black.ttf") format("truetype"), url("../fonts/AvenirLTStd-Black.woff") format("woff"), url("../fonts/AvenirLTStd-Black.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 36em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns, .newslist .row.collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row, .newslist .row.collapse > .latestnews-item > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns, .newslist .latestnews-item {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 36em) {
  .column, .columns, .newslist .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns, .newslist .row.row.latestnews-item {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns, .row .newslist .row.row.latestnews-item, .newslist .row .row.row.latestnews-item {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12, .newslist .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns, .newslist .small-up-1 > .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns, .newslist .small-up-2 > .latestnews-item {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns, .newslist .small-up-3 > .latestnews-item {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns, .newslist .small-up-4 > .latestnews-item {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns, .newslist .small-up-5 > .latestnews-item {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns, .newslist .small-up-6 > .latestnews-item {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns, .newslist .small-up-7 > .latestnews-item {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns, .newslist .small-up-8 > .latestnews-item {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns, .newslist .small-collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns, .newslist .small-uncollapse > .latestnews-item {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns, .newslist .smedium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns, .newslist .smedium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns, .newslist .smedium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns, .newslist .smedium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns, .newslist .smedium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns, .newslist .smedium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns, .newslist .smedium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns, .newslist .smedium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns, .newslist .smedium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns, .newslist .smedium-uncollapse > .latestnews-item {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 36em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns, .newslist .medium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns, .newslist .medium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns, .newslist .medium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns, .newslist .medium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns, .newslist .medium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns, .newslist .medium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns, .newslist .medium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns, .newslist .medium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 36em) and (min-width: 36em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 36em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns, .newslist .medium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns, .newslist .medium-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 48em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .mlarge-offset-0 {
    margin-left: 0%;
  }
  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-offset-3 {
    margin-left: 25%;
  }
  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .mlarge-offset-6 {
    margin-left: 50%;
  }
  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .mlarge-offset-9 {
    margin-left: 75%;
  }
  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns, .newslist .mlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns, .newslist .mlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns, .newslist .mlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns, .newslist .mlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns, .newslist .mlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns, .newslist .mlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns, .newslist .mlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns, .newslist .mlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns, .newslist .row.mlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .mlarge-collapse > .column, .mlarge-collapse > .columns, .newslist .mlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns, .newslist .mlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 60em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns, .newslist .large-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns, .newslist .large-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns, .newslist .large-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns, .newslist .large-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns, .newslist .large-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns, .newslist .large-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns, .newslist .large-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns, .newslist .large-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns, .newslist .large-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns, .newslist .large-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 64em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns, .newslist .xlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns, .newslist .xlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns, .newslist .xlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns, .newslist .xlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns, .newslist .xlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns, .newslist .xlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns, .newslist .xlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns, .newslist .xlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 64em) and (min-width: 64em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 64em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns, .newslist .xlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns, .newslist .xlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 36em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
.animated {
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}
.animated.visible {
  visibility: visible;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-5%, 0, 0);
    transform: translate3d(-5%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-5%, 0, 0);
    transform: translate3d(-5%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes border {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5%, 0, 0);
    transform: translate3d(5%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes border-before {
  0% {
    width: 0%;
    height: 0;
  }
  50% {
    width: 0;
    height: 115%;
    border-left: 1px solid #000;
  }
  100% {
    width: 26%;
    height: 115%;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
  }
}
/* Main
================================================== */
.label-warning {
  display: none;
}

/* Components
================================================== */
/* Header
================================================== */
header {
  position: relative;
  z-index: 1;
}
header .row {
  max-width: 100%;
}
header .row .column, header .row .columns, header .row .newslist .latestnews-item, .newslist header .row .latestnews-item {
  padding-left: 0;
  padding-right: 0;
  background-color: #f5b04f;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
header .row .column .header__topBanner, header .row .columns .header__topBanner, header .row .newslist .latestnews-item .header__topBanner, .newslist header .row .latestnews-item .header__topBanner {
  width: 100%;
}
header .row .column .header__topBanner p, header .row .columns .header__topBanner p, header .row .newslist .latestnews-item .header__topBanner p, .newslist header .row .latestnews-item .header__topBanner p {
  margin: 0;
}
header .row .column .header__topBanner img, header .row .columns .header__topBanner img, header .row .newslist .latestnews-item .header__topBanner img, .newslist header .row .latestnews-item .header__topBanner img {
  width: 100%;
  display: block;
}
@media (max-width: 479px) {
  header .header__additionalItems .button--calendar {
    display: none;
  }
}
header .header__additionalItems p {
  margin: 0;
}
header .header__additionalItems .language {
  transition: all 400ms ease-in-out;
}
header .header__additionalItems .language:hover {
  color: #194894;
  transition: all 400ms ease-in-out;
}
header .header__additionalItems .language:hover svg path {
  fill: #194894;
  transition: all 400ms ease-in-out;
}
header .header__additionalItems .language svg path {
  transition: all 400ms ease-in-out;
}

.banner.active {
  z-index: -1;
}

#banner {
  position: relative;
  min-height: 20em;
  height: 400px;
  overflow: hidden;
  z-index: 0;
  /*img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    object-position: 100% 150%;
  }*/
}
#banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#banner .overlay:after {
  content: "";
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.4;
}
#banner .overlay__items {
  position: absolute;
  bottom: 1em;
  left: 2em;
  z-index: 2;
}
@media (max-width: 479px) {
  #banner .overlay__items {
    left: 1em;
  }
}
#banner .overlay__items .button {
  background: #7795cb;
}
#banner .overlay__items .button:hover {
  background: #194894;
}
@media (max-width: 479px) {
  #banner .overlay__items .button {
    width: 48%;
    text-align: center;
    font-size: 1em;
    padding: 1em;
  }
}
@media (max-width: 479px) {
  #banner .overlay__items .button:last-child {
    width: 97%;
  }
}
#banner .overlay__items--logo {
  position: absolute;
  bottom: 7em;
  left: 2em;
  z-index: 2;
  width: 5em;
  height: 5em;
}
@media (max-width: 479px) {
  #banner .overlay__items--logo {
    bottom: 10em;
  }
}
#banner .overlay__items--logo img {
  width: 100%;
  height: auto;
}
#banner p {
  margin: 0;
}

/* Navigation
================================================== */
header {
  z-index: 2;
}

.nav--main .row {
  position: unset;
}
.nav--main .header__nav {
  width: 100%;
  background-color: #194894;
  padding: 0 1em;
}
@media (max-width: 1199px) {
  .nav--main .header__nav {
    display: none;
    margin-top: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    max-height: 0;
    width: 100%;
    background: #f5b04f;
    transition: max-height 0.6s ease-in-out;
    -webkit-transition: max-height 0.6s ease-in-out;
    -moz-transition: max-height 0.6s ease-in-out;
    -ms-transition: max-height 0.6s ease-in-out;
    -o-transition: max-height 0.6s ease-in-out;
  }
}
.nav--main .header__nav.active {
  max-height: 100rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  position: fixed;
  overflow: hidden;
}
.nav--main .header__nav.active li {
  text-align: center;
}
@media (max-width: 959px) {
  .nav--main .header__nav.active li a {
    padding-top: 1em;
    padding-bottom: 1em;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 959px) {
  .nav--main .header__nav.active .nav {
    display: block;
  }
  .nav--main .header__nav.active a {
    display: block;
    padding: 0.6em;
    margin: 0 auto;
  }
  .nav--main .header__nav.active li,
  .nav--main .header__nav.active ul.active {
    width: 100%;
    float: left;
    display: block;
    font-size: 1em !important;
  }
  .nav--main .header__nav.active ul.active {
    display: block;
    position: static;
    top: auto;
  }
}
.nav--main .header__additional {
  background-color: #f5b04f;
  width: 100%;
  padding: 0.7em;
}
@media (max-width: 1199px) {
  .nav--main .header__additional {
    position: relative;
  }
}
@media (max-width: 479px) {
  .nav--main .header__additional {
    width: calc(100% - 4em);
    background-color: transparent;
  }
}
.nav--main .header__additional .header__additionalItems {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  margin-right: 1em;
}
@media (max-width: 479px) {
  .nav--main .header__additional .header__additionalItems {
    justify-content: flex-end;
  }
}
.nav--main .header__additional .header__additionalItems a {
  margin-bottom: 0;
}
.nav--main .header__additional .language {
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 1em;
}
.nav--main .header__additional .language svg {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  transform: translateY(0.2em) rotate(-90deg);
  transition: all 0.5s ease-in;
}
.nav--main .header__additional .language.open svg {
  transform: translateY(0.2em) rotate(90deg);
}
.nav--main #google_translate_element {
  position: absolute;
  top: 100%;
  right: 11em;
  background: #f5b04f;
  padding: 1em;
  transition: right 1s ease-in;
  display: none;
}
@media (max-width: 479px) {
  .nav--main #google_translate_element {
    right: 0;
  }
}
.nav--main .nav {
  width: 100%;
  margin: 0;
  display: none;
  /*i {
    margin-left: 5px;
    display: none;
  }*/
}
@media only screen and (min-width: 1650px) {
  .nav--main .nav {
    max-width: 125em;
  }
}
.nav--main .nav.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1200px) {
  .nav--main .nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
@media (max-width: 1199px) {
  .nav--main .nav {
    margin-top: 3em;
  }
}
.nav--main .nav li {
  position: relative;
  text-align: center;
  list-style: none;
  left: 0;
  width: auto;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.nav--main .nav li.parent {
  position: relative;
}
@media (max-width: 479px) {
  .nav--main .nav li.parent {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
@media (min-width: 960px) {
  .nav--main .nav li.parent a {
    padding-right: 23px;
  }
}
.nav--main .nav li.parent > a:after {
  content: "";
  display: table;
  width: 0;
  height: 0;
  position: absolute;
  right: 6px;
  top: 1rem;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #ffffff;
  -webkit-transition: border-top 400ms ease-in-out;
  -moz-transition: border-top 400ms ease-in-out;
  -ms-transition: border-top 400ms ease-in-out;
  -o-transition: border-top 400ms ease-in-out;
}
@media (min-width: 960px) {
  .nav--main .nav li.parent > a:after {
    top: 43%;
  }
}
@media (max-width: 479px) {
  .nav--main .nav li.parent > a:after {
    right: 9em;
  }
}
.nav--main .nav li.parent > a:hover:after, .nav--main .nav li.parent.active > a:after, .nav--main .nav li.parent.current > a:after {
  border-top: 6px solid #194894;
}
@media (min-width: 960px) {
  .nav--main .nav li.parent li a:hover {
    background-color: #7795cb;
  }
}
@media (min-width: 960px) {
  .nav--main .nav li.parent li.current a {
    background-color: #7795cb;
  }
}
.nav--main .nav a {
  display: block;
  padding: 17px 11px;
  color: #ffffff;
  font-size: 0.8em;
  text-decoration: none;
  line-height: 120%;
  font-family: "AvenirBlack", sans-serif;
  text-transform: uppercase;
  max-width: 12em;
}
@media (min-width: 960px) {
  .nav--main .nav a {
    padding: 17px 6px;
  }
}
@media (min-width: 1200px) {
  .nav--main .nav a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 17px 11px;
  }
}
@media only screen and (min-width: 1350px) {
  .nav--main .nav a {
    font-size: 1em;
  }
}
.nav--main .nav a:hover,
.nav--main .nav .current > a,
.nav--main .nav .active > a {
  color: #194894;
}
@media (max-width: 959px) {
  .nav--main .nav .nav-child {
    width: 120%;
    margin-left: -10%;
  }
}
.nav--main .nav .nav-child a {
  font-size: 0.8em;
  transition: all 0.2s ease-in;
  width: 100%;
  text-align: left;
}
@media (max-width: 479px) {
  .nav--main .nav .nav-child a {
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    float: none;
    width: 100%;
    text-align: center;
  }
}
.nav--main .nav .nav-child a:hover {
  color: #ffffff;
  background: #7795cb;
  width: 100%;
}
.nav--main .nav .nav-child li.current.active a {
  color: #ffffff;
}
.nav--main .nav .nav-child li {
  background: #194894;
  z-index: 99;
}
@media (max-width: 479px) {
  .nav--main .nav .nav-child li {
    height: 4em;
  }
}
.nav--main .nav ul {
  display: none;
  width: 100%;
  float: left;
  position: static;
  margin: 0;
  font-size: 0.8em;
}
@media (min-width: 960px) {
  .nav--main .nav ul {
    position: absolute;
    min-width: 150px;
    width: auto;
    top: 100%;
    left: 0;
    z-index: 9;
    font-size: 1em;
    background-color: #194894;
  }
}
@media (max-width: 959px) {
  .nav--main .nav ul.active {
    display: block;
  }
}
.nav--main .nav ul li {
  width: 100%;
}
@media (min-width: 960px) {
  .nav--main .nav li:hover > ul {
    display: block;
  }
}
.nav--main .nav > * {
  overflow: visible;
}
@media (max-width: 479px) {
  .nav--main .nav.active a {
    padding-left: 0;
  }
}

.menu-link {
  height: 2.3em;
  width: 2.3em;
  position: relative;
  z-index: 100;
  display: block;
  cursor: pointer;
  margin: 10px 0;
  background-color: transparent;
  padding: 0;
  margin-left: 1em;
}
.menu-link:hover {
  background: none;
}
.menu-link span,
.menu-link span:before,
.menu-link span:after {
  width: 35px;
  height: 3px;
  position: absolute;
  top: 17px;
  display: block;
  content: "";
  background: #ffffff;
  border-radius: 1px;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.menu-link span:before {
  top: -10px;
}
.menu-link span:after {
  top: auto;
  bottom: -10px;
}
.menu-link.active {
  position: fixed;
  top: 1em;
}
.menu-link.active span {
  background: transparent;
}
.menu-link.active span:before, .menu-link.active span:after {
  top: 0;
  background: #ffffff;
}
.menu-link.active span:before {
  transform: rotate(45deg);
}
.menu-link.active span:after {
  transform: rotate(-45deg);
}
@media (min-width: 1200px) {
  .menu-link {
    display: none;
  }
}

/* Banner / Slider
================================================== */
/* Page Specific Styling
================================================== */
#home main {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3em;
}
@media (min-width: 1200px) {
  #home main {
    margin-top: 6em;
    margin-bottom: 9em;
  }
}
@media (max-width: 575px) {
  #home main {
    width: 100% !important;
  }
}
@media (max-width: 479px) {
  #home main {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
#home main .row {
  display: flex;
  flex-wrap: wrap;
}
#home main:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 98%;
  height: 270%;
  background-image: url(/images/venue3.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: right top;
  z-index: -1;
}
@media (min-width: 1200px) {
  #home main:after {
    width: 1550px;
    height: 176%;
  }
}
@media (max-width: 479px) {
  #home main:after {
    top: -1em;
  }
}
#home main .main-content {
  position: relative;
}
#home main .main-content:before {
  content: "";
  position: absolute;
  width: 26%;
  height: 100%;
  top: 3em;
  left: -2em;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  z-index: 0;
}
@media (max-width: 479px) {
  #home main .main-content:before {
    display: none;
  }
}
#home main .main-content:after {
  content: "";
  position: absolute;
  width: 1.5%;
  height: 115%;
  top: 3em;
  left: -2em;
  border-top: 1px solid #000;
}
#home main .main-content.animated:before {
  width: 26%;
  height: 115%;
  animation-delay: 2s;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}
@media (max-width: 959px) {
  #home main .main-content.animated:before {
    height: 100%;
  }
}
#home main .main-content.animated:after {
  width: 3%;
  height: 115%;
  animation: border-after 1s linear forwards;
  animation-delay: 2s;
  border-top: 1px solid #000;
}
#home main h1 {
  color: #7795cb;
}
@media (max-width: 479px) {
  #home main h1 {
    font-size: 2em;
  }
}
#home main .item-page > div {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
#home main .item-page > div .topSection--left {
  width: 60%;
  position: relative;
  padding-right: 2em;
}
@media (max-width: 1023px) {
  #home main .item-page > div .topSection--left {
    width: 90%;
  }
}
@media (max-width: 479px) {
  #home main .item-page > div .topSection--left {
    width: 100%;
  }
}
#home main .item-page > div .topSection--right {
  width: 40%;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
}
@media (max-width: 1023px) {
  #home main .item-page > div .topSection--right {
    width: 100%;
    z-index: 90;
  }
}
@media (max-width: 479px) {
  #home main .item-page > div .topSection--right .button {
    margin-top: 2em;
  }
}
#home .sidebar {
  padding-top: 10em;
}
@media (max-width: 575px) {
  #home .sidebar {
    width: 100% !important;
    padding-top: 8em;
  }
}
#home .sidebar iframe {
  width: 100%;
  height: auto;
  min-height: 18em;
}
#home .experienceSlider__section {
  height: 25em;
}
@media (min-width: 1200px) {
  #home .experienceSlider__section {
    height: 35em;
  }
}

.featured-text {
  color: #7795cb;
  font-size: 2.14em;
  font-family: "AvenirBlack", sans-serif;
  line-height: 1.2;
}

.tabs__section {
  margin-bottom: 3em;
  margin-top: 3em;
}
@media (max-width: 479px) {
  .tabs__section {
    margin-top: 1.5em;
    margin-bottom: 4.5em;
  }
}

.tabs__sections {
  min-height: 30em;
  height: auto;
}
.tabs__sections .section {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2em;
  display: none;
}
.tabs__sections .section.active {
  display: flex;
}
.tabs__sections .section .button {
  margin-top: 1em;
}
.tabs__sections .section .section__content {
  width: 60%;
  padding-right: 5em;
}
@media (max-width: 1023px) {
  .tabs__sections .section .section__content {
    width: 100%;
    margin-bottom: 3em;
  }
}
@media (max-width: 479px) {
  .tabs__sections .section .section__content {
    padding-right: 0;
  }
}
.tabs__sections .section .section__image {
  width: 40%;
  max-width: 32.143em;
  position: relative;
}
@media (max-width: 1023px) {
  .tabs__sections .section .section__image {
    width: 100%;
  }
}
.tabs__sections .section .section__image .section__image--wrap {
  position: relative;
  height: 25em;
  overflow: hidden;
  width: 100%;
}
.tabs__sections .section .section__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  max-width: inherit;
  min-width: 100%;
  height: 100%;
}
.tabs__sections .section .section__image:after {
  content: "";
  position: absolute;
  top: 20%;
  right: -10%;
  width: 100%;
  height: 90%;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  z-index: -1;
}
@media (max-width: 1199px) {
  .tabs__sections .section .section__image:after {
    height: 70%;
  }
}
@media (max-width: 479px) {
  .tabs__sections .section .section__image:after {
    display: none;
  }
}

.tabs_buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #194894;
}
.tabs_buttons .tab {
  color: #bdc3c7;
  width: auto;
}
@media (max-width: 479px) {
  .tabs_buttons .tab {
    width: 100%;
  }
}
.tabs_buttons .tab.active {
  color: #194894;
}
.tabs_buttons h2 {
  font-family: "AvenirBook", sans-serif;
  cursor: pointer;
}

.experienceSlider__section {
  position: relative;
  padding-top: 3em;
  padding-bottom: 3em;
}
@media (max-width: 479px) {
  .experienceSlider__section {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
.experienceSlider__section.animated h2:before {
  width: 22%;
}
@media (max-width: 1199px) {
  .experienceSlider__section.animated h2:before {
    width: 16%;
  }
}
@media (max-width: 959px) {
  .experienceSlider__section.animated h2:before {
    width: 4%;
    left: -0.5em;
  }
}
@media (max-width: -1) {
  .experienceSlider__section.animated h2:before {
    left: -0.5em;
    width: 5%;
    top: 2.2em;
  }
}
.experienceSlider__section.animated h2:after {
  width: 22%;
}
@media (max-width: 1199px) {
  .experienceSlider__section.animated h2:after {
    width: 16%;
  }
}
@media (max-width: 1023px) {
  .experienceSlider__section.animated h2:after {
    width: 13%;
  }
}
@media (max-width: 959px) {
  .experienceSlider__section.animated h2:after {
    width: 4%;
    right: -0.5em;
  }
}
@media (max-width: -1) {
  .experienceSlider__section.animated h2:after {
    right: -0.5em;
    width: 5%;
    top: 2.3em;
  }
}
.experienceSlider__section h2 {
  margin: 0 auto 1em auto;
  max-width: 20em;
  text-align: center;
}
.experienceSlider__section .news {
  padding: 0 1em !important;
}
.experienceSlider__section .newsintro {
  text-transform: uppercase;
  font-size: 1.286em;
  line-height: 1.3;
  margin-top: 1.5em;
  white-space: normal;
  text-align: center !important;
}
.experienceSlider__section .cycle-carousel-wrap .latestnews-item img {
  object-fit: cover;
}

.newshead .picture .innerpicture {
  left: unset !important;
  width: unset !important;
  position: relative;
}

#prev svg,
#prevG svg,
#next svg,
#nextG svg {
  width: 2em;
  height: 2em;
  position: absolute;
  top: -2em;
}

#next svg,
#prev svg {
  top: -2em;
}

#prev svg,
#prevG svg {
  transform: rotate(180deg);
}

.center {
  display: flex;
  justify-content: space-between;
}

.slider__controls {
  position: absolute;
  width: 103%;
  margin-left: -3em;
  top: 18em;
}
@media (max-width: 479px) {
  .slider__controls {
    width: 95%;
    margin-left: -1.5em;
  }
}

#post-show-g {
  position: relative;
}
#post-show-g .slider__controls {
  top: 60%;
}

.fullwidth-section {
  position: relative;
}
.fullwidth-section .row {
  max-width: 100%;
  width: 100%;
}
.fullwidth-section .row .column,
.fullwidth-section .row .columns,
.fullwidth-section .row .newslist .latestnews-item,
.newslist .fullwidth-section .row .latestnews-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.countdown__section {
  width: 100%;
  position: relative;
  height: 25em;
  overflow: hidden;
  margin: 3em 0;
}
.countdown__section:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #7795cb;
  z-index: -1;
}
.countdown__section h2 {
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 1.5em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.countdown__section p {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.countdown__section img {
  position: absolute;
  min-width: 100%;
  object-fit: cover;
  max-width: unset;
  height: 100%;
  z-index: 0;
}
.countdown__section .countdownModule {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 6em;
  z-index: 5;
}
.countdown__section .countdownModule h3 {
  display: none;
}

.counter {
  position: absolute;
  width: 100%;
  height: 20em;
  bottom: 2em;
  left: 0;
}
@media (max-width: -1) {
  .counter {
    bottom: -4em;
  }
}

.soon-text {
  color: #ffffff;
}

.keyDates__section {
  padding: 3em 0em 6em 0em;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 479px) {
  .keyDates__section {
    padding: 2em 0em 2em 0em;
  }
}
.keyDates__section:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 74%;
  border: 1px solid #000;
  top: 3em;
  left: 0;
  z-index: -1;
}
@media (max-width: 479px) {
  .keyDates__section:before {
    top: 2em;
    height: 95%;
  }
}
.keyDates__section h2 {
  margin: 0 auto 2em auto;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 959px) {
  .keyDates__section h2 {
    margin: 0 auto 1em auto;
  }
}
.keyDates__section h2:after {
  content: "";
  position: absolute;
  width: 30%;
  height: 1px;
  background: #fff;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 1 !important;
}
@media (max-width: 479px) {
  .keyDates__section h2:after {
    opacity: 1;
  }
}
.keyDates__section h2:after {
  right: 0;
  left: unset;
}
.keyDates__section .datesSection {
  width: 30%;
  text-align: center;
}
@media (max-width: 959px) {
  .keyDates__section .datesSection {
    width: 50%;
  }
  .keyDates__section .datesSection:last-child {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .keyDates__section .datesSection {
    width: 100%;
  }
}
.keyDates__section .datesSection h3 {
  color: #7795cb;
}
.keyDates__section .datesSection h3 svg {
  width: 1.2em;
  height: 1.2em;
  margin-right: 1em;
  transform: translateY(0.2em);
}
.keyDates__section .datesSection p {
  text-transform: uppercase;
}
.keyDates__section.animated h2:before, .keyDates__section.animated h2:after {
  width: 30%;
}
@media (max-width: 479px) {
  .keyDates__section.animated h2:before, .keyDates__section.animated h2:after {
    width: 15%;
  }
}

.feedSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4em;
  margin-bottom: 3em;
}
@media (max-width: 479px) {
  .feedSection {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
.feedSection .feedSection__tweeter {
  width: 57%;
}
@media (max-width: 959px) {
  .feedSection .feedSection__tweeter {
    width: 100%;
    text-align: center;
    margin-bottom: 3em;
  }
}
.feedSection .feedSection__tweeter .feedSection__title:before, .feedSection .feedSection__tweeter .feedSection__title:after {
  width: 28%;
}
@media (max-width: 479px) {
  .feedSection .feedSection__tweeter .feedSection__title:before, .feedSection .feedSection__tweeter .feedSection__title:after {
    width: 5%;
  }
}
.feedSection .feedSection__additional {
  width: 100%;
}
@media (max-width: 959px) {
  .feedSection .feedSection__additional {
    width: 100%;
    margin-top: 3em;
    margin-bottom: 3em;
  }
}
@media (max-width: 479px) {
  .feedSection .feedSection__additional {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
.feedSection .feedSection__additional .feedSection__title:before, .feedSection .feedSection__additional .feedSection__title:after {
  width: 22%;
}
@media (max-width: 479px) {
  .feedSection .feedSection__additional .feedSection__title:before, .feedSection .feedSection__additional .feedSection__title:after {
    width: 10%;
  }
}
.feedSection .feedSection__additional .feedSection__imageWrap {
  width: 100%;
  min-height: 22em;
  height: auto;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1500px) {
  .feedSection .feedSection__additional .feedSection__imageWrap {
    min-height: 30em;
  }
}
.feedSection .feedSection__additional .feedSection__imageWrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.feedSection .feedSection__additional .feedSection__imageWrap img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  max-width: unset;
}
.feedSection .feedSection__additional .feedSection__imageWrap a {
  z-index: 2;
  color: #ffffff;
  border-color: #ffffff;
  margin-bottom: 0;
}
.feedSection .feedSection__additional .feedSection__imageWrap a:hover {
  color: #194894;
  background: #ffffff;
}
.feedSection .feedSection__title {
  position: relative;
  width: 100%;
  text-align: center;
}
.feedSection .feedSection__title:before, .feedSection .feedSection__title:after {
  position: absolute;
  top: 37%;
  content: "";
  height: 1px;
  background: #000;
  width: 50%;
}
.feedSection .feedSection__title:before {
  left: 0;
}
.feedSection .feedSection__title:after {
  right: 0;
}
.feedSection .feedSection__title h2 {
  width: auto;
  background: #ffffff;
  z-index: 1;
}

.othetEvents__section {
  position: relative;
  margin-top: 3em;
  margin-bottom: 3em;
  clear: both;
  display: block;
  float: left;
  width: 100%;
}
@media (max-width: 479px) {
  .othetEvents__section {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
@media (max-width: -1) {
  .othetEvents__section {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.othetEvents__section:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  border-top: 1px solid #000;
  top: 1em;
  left: 0;
  z-index: -1;
}
.othetEvents__section h2 {
  margin: 0 auto 2em auto;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 479px) {
  .othetEvents__section h2 {
    margin: 0 auto 1em auto;
  }
}
.othetEvents__section h2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 12em;
  height: 1em;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: -1;
}
@media (max-width: 479px) {
  .othetEvents__section h2:after {
    width: 8em;
  }
}
.othetEvents__section p {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 479px) {
  .othetEvents__section p {
    justify-content: space-around;
  }
}
.othetEvents__section p a {
  width: 15%;
  max-width: 15%;
  height: auto;
  /*&:nth-child(2) {
      width: 8%;
      max-width: 8%;

      @include bp(medium) {
        width: 30%;
        max-width: 30%;
        margin-bottom: 3em;
      }
    }

    &:nth-child(1) {
      width: 20%;
      max-width: 20%;

      @include bp(medium) {
        width: 30%;
        max-width: 30%;
        margin-bottom: 3em;
      }
    }*/
}
.othetEvents__section p a.reduced {
  width: 15%;
}
@media (max-width: 575px) {
  .othetEvents__section p a.reduced {
    width: 45%;
  }
}
@media (max-width: 575px) {
  .othetEvents__section p a {
    width: 45%;
    max-width: 45%;
    margin-bottom: 3em;
  }
}

@media (max-width: 479px) {
  main {
    z-index: 0;
  }
}

@media (max-width: 575px) {
  .mobile-top {
    margin-top: 2em;
  }
}

@media (max-width: 479px) {
  .mobile-no-bottom {
    margin-bottom: 0;
  }
}

.main-content {
  margin: 3em 0 0;
}

@media (max-width: 479px) {
  .row-narrow-mobile {
    max-width: 80%;
  }
}

@media (min-width: 960px) {
  .mobile-menu-link {
    display: none !important;
  }
}

.contentBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contentBox__content,
.contentBox__image {
  width: 48%;
  overflow: hidden;
}
@media (max-width: 959px) {
  .contentBox__content,
  .contentBox__image {
    width: 100%;
  }
}
.contentBox__content iframe,
.contentBox__image iframe {
  width: 100%;
}

.contentBox__content {
  padding-right: 2em;
}
@media (max-width: 479px) {
  .contentBox__content {
    padding-right: 0;
  }
}

#contact .contentBox {
  text-align: center;
}

@media (max-width: 479px) {
  .contentBox:nth-child(2) .contentBox__image {
    margin-bottom: 2em;
  }
}

.sticky {
  position: sticky;
  top: 1em;
}
@media (max-width: 959px) {
  .sticky {
    position: unset;
  }
}

.social--module {
  position: fixed;
  top: 60vh;
  right: 0;
}
@media (max-width: 479px) {
  .social--module {
    bottom: 0;
    top: unset;
    right: 0;
    width: 100%;
    z-index: 100;
  }
}
.social--module .social {
  width: 4em;
  height: 13em;
  background: #f3f4f4;
  display: flex;
  flex-direction: column;
  padding-top: 1.5em;
}
@media (max-width: 479px) {
  .social--module .social {
    width: 100%;
    height: 4em;
    flex-direction: row;
    padding-top: 1em;
  }
}
.social--module .social__el {
  width: 4em;
  height: 3em;
  text-align: center;
}
@media (max-width: 479px) {
  .social--module .social__el {
    width: 25%;
    height: 4em;
  }
}
.social--module .social svg {
  width: 1.5em;
  height: 1.5em;
}
@media (max-width: 479px) {
  .social--module .social svg {
    width: 2em;
    height: 2em;
  }
}

@media (max-width: 479px) {
  h2:before, h2:after,
  .feedSection__title:before,
  .feedSection__title:after,
  .othetEvents__section:before,
  .othetEvents__section:after {
    opacity: 0;
  }
}

.sponsorship-image {
  border-bottom: 1px solid #787878;
}

#sponsor .featured-text {
  margin: 1em 0;
}
#sponsor .button {
  margin-right: 1em;
}

.partners__module {
  margin: 3em 0;
  position: relative;
  /*&:before {
        content: "";
        position: absolute;
        width: 100%;
      	height: 1px;
        border-top: 1px solid #000;
        top: 1em;
        left: 0;
        z-index: -1;

     @media $mobile-wide-max {
       display: none;
     }
      }*/
}
@media (max-width: 479px) {
  .partners__module {
    margin: 1.5em 0;
  }
}
.partners__module ul {
  list-style: none;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.partners__module ul li {
  width: 14em;
  padding: 1.2em;
}
.partners__module ul li img {
  vertical-align: middle;
  max-width: 11em;
  width: 100%;
}
.partners__module h2 {
  margin: 0 auto 2em auto;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 479px) {
  .partners__module h2 {
    margin: 0 auto 1em auto;
  }
}
.partners__module h2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 14em;
  height: 1em;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: -1;
}
@media (max-width: 479px) {
  .partners__module h2:after {
    width: 8em;
  }
}
.partners__module .title-wrap:before {
  top: 2em;
}

.ba-gallery-grid {
  min-height: 68em;
}
.ba-gallery-grid:before {
  content: "";
  display: table;
  clear: both;
}

.tabs__sections--program h3 {
  margin-top: 2em;
}
@media (max-width: 479px) {
  .tabs__sections--program h3 {
    margin-top: 1em;
  }
}
.tabs__sections--program p {
  width: 100%;
  clear: both;
}
.tabs__sections--program .name {
  color: #194894;
}

#register .item-page > div {
  min-height: 35em;
}

.CBiframe {
  height: 38em !important;
  min-height: 38em !important;
}

.title-wrap {
  width: 100%;
}
.title-wrap:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  border-top: 1px solid #000;
  top: 1em;
  left: 0;
  z-index: -1;
}
@media (max-width: 479px) {
  .title-wrap:before {
    display: none;
  }
}

.experienceSlider__section:before {
  content: "";
  position: absolute;
  width: 15%;
  height: 1px;
  border-top: 1px solid #000;
  top: 4em;
  left: 0;
  z-index: -1;
}
@media (min-width: 1200px) {
  .experienceSlider__section:before {
    width: 24%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .experienceSlider__section:before {
    width: 10%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1000px) {
  .experienceSlider__section:before {
    width: 7%;
  }
}
@media (min-width: 576px) {
  .experienceSlider__section:before {
    width: 7%;
  }
}
@media (max-width: 479px) {
  .experienceSlider__section:before {
    display: none;
  }
}
.experienceSlider__section:after {
  content: "";
  position: absolute;
  width: 15%;
  height: 1px;
  border-top: 1px solid #000;
  top: 4em;
  right: 0;
  z-index: -1;
}
@media (max-width: 479px) {
  .experienceSlider__section:after {
    display: none;
  }
}
@media (min-width: 1200px) {
  .experienceSlider__section:after {
    width: 24%;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .experienceSlider__section:after {
    width: 10%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1000px) {
  .experienceSlider__section:after {
    width: 7%;
  }
}
@media (min-width: 576px) {
  .experienceSlider__section:after {
    width: 7%;
  }
}
@media (max-width: 479px) {
  .experienceSlider__section:after {
    display: none;
  }
}
.experienceSlider__section h2 {
  margin: 0 auto 2em auto;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 959px) {
  .experienceSlider__section h2 {
    max-width: 80%;
  }
}
@media (max-width: 479px) {
  .experienceSlider__section h2 {
    margin: 0 auto 1em auto;
    max-width: 100%;
  }
}
.experienceSlider__section h2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 19em;
  height: 1em;
  transform: translateX(-50%);
  z-index: -1;
}
@media (max-width: 479px) {
  .experienceSlider__section h2:after {
    width: 8em;
  }
}

.experienceSlider__section.guangzhou {
  /*&:before,
  &:after {
  	 width: 15%;

    @include bp(xxlarge) {
      width: 8%;
    }

    @include bp(large) {
      width: 8%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    border-top: 1px solid #000;
    top: 4em;
    left: unset;
    right: 0;
    z-index: -1;
  }

  h2:after {
    display: none;
  }*/
}
.experienceSlider__section.guangzhou h2:after {
  width: 21em;
}

.keyDates__section .title-wrap:before {
  opacity: 0;
}
@media (max-width: 479px) {
  .keyDates__section .title-wrap:before {
    display: block;
  }
}
.keyDates__section h2.line-title {
  margin: -0.5em auto 2em auto;
}
@media (max-width: 479px) {
  .keyDates__section h2.line-title {
    margin: -2em auto 2em auto;
  }
}
.keyDates__section h2.line-title:after {
  width: 8em;
}
.keyDates__section.other-title:after {
  width: 8em !important;
}

h2.line-title {
  margin: 0 auto 2em auto;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}
@media (max-width: 479px) {
  h2.line-title {
    margin: 0 auto 1em auto;
  }
}
h2.line-title:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 12em;
  height: 1em;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: -1;
}
@media (max-width: 479px) {
  h2.line-title:after {
    width: 8em;
  }
}
h2.line-title.associates-title:after {
  width: 13em;
}
h2.line-title.other-title:after {
  width: 13em;
}

.secondary-page {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 959px) {
  .secondary-page {
    flex-direction: column-reverse;
  }
}
@media (max-width: 959px) {
  .secondary-page .title,
  .secondary-page .cta {
    width: 100% !important;
  }
}
.secondary-page .title {
  width: 70%;
}
.secondary-page .cta {
  width: 30%;
  align-self: flex-start;
}
.secondary-page .cta a {
  float: right;
}
@media (max-width: 959px) {
  .secondary-page .cta a {
    float: none;
  }
}

.internal .main-content i.fa.fa-caret-down {
  display: none;
}

* {
  box-sizing: border-box;
}

/* force scrollbar */
html {
  overflow-y: scroll;
}

/* ---- grid ---- */
.grid {
  background: none;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */
.grid-sizer,
.grid-item {
  width: 24.5%;
  margin-bottom: 0.6%;
}
@media (max-width: 575px) {
  .grid-sizer,
  .grid-item {
    width: 49%;
  }
}
@media (max-width: -1) {
  .grid-sizer,
  .grid-item {
    width: 100%;
  }
}

.gutter-sizer {
  width: 0.6%;
}
@media (max-width: -1) {
  .gutter-sizer {
    width: 0;
  }
}

.grid-item {
  float: left;
}

.loading-message {
  animation: fadeOut 5s forwards;
}

@media (min-width: 960px) {
  .lnee.newslist {
    height: 15em;
  }
}
@media (min-width: 960px) and (min-width: 1200px) {
  .lnee.newslist {
    height: 20em;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #post-show-g {
    height: 35em;
  }
  .feedSection .feedSection__additional .feedSection__imageWrap a {
    margin-top: 35%;
  }
}
/* News
================================================== */
.newslist .latestnews-items {
  flex-flow: column !important;
  align-items: normal !important;
  -webkit-justify-content: normal !important;
  -ms-flex-pack: normal !important;
  justify-content: normal !important;
  overflow: visible !important;
}
@media (min-width: 576px) {
  .newslist .latestnews-items {
    flex-flow: row wrap !important;
  }
}
.newslist .latestnews-item {
  flex: 1;
  padding: 0 1rem 2rem !important;
}
@media (min-width: 576px) {
  .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 960px) {
  .newslist .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
.newslist .latestnews-item > a {
  display: block;
  height: 100%;
}
.newslist .latestnews-item > a:hover .detail_category,
.newslist .latestnews-item > a:hover .detail_tags {
  opacity: 1;
}
.newslist .latestnews-item > a:hover .link a {
  background-color: #194894;
}
.newslist .latestnews-item > a:hover .link a span {
  color: #ffffff;
}
.newsSection .newslist .latestnews-item {
  border: 0;
}
.newslist .latestnews-item.active {
  opacity: 1 !important;
}
.newslist .newshead {
  float: none !important;
  margin: 0 !important;
}
.newslist .innerpicture {
  width: 100%;
}
.newslist .innerpicture:after {
  content: "";
  display: block;
  padding-bottom: 60%;
}
.newslist .innerpicture img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newslist .news {
  position: relative !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0rem !important;
  background-color: transparent !important;
  border: none !important;
}
.newslist .newstitle {
  font-size: 1.6rem;
  font-family: Helvetica, sans-serif !important;
  font-weight: normal !important;
  line-height: 120% !important;
  margin: 0 0 0.5em 0 !important;
}
.newslist .newstitle a {
  color: #194894;
}
.newslist .newstitle a:hover {
  color: #f5b04f;
}
.newslist .newsinfo {
  padding: 1.5rem 1rem 4rem !important;
}
.newslist .newsinfo * {
  color: #194894;
}
.newslist .newsintro {
  line-height: 1.5 !important;
}
.newslist .detail_category,
.newslist .detail_tags {
  opacity: 1;
  display: inline-flex;
  padding: 0.4rem 0.8rem;
  border-radius: 50px;
  background: #edf0ef;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: #194894;
  transition: 400ms ease-in-out;
}
@media (min-width: 576px) {
  .newslist .detail_category,
  .newslist .detail_tags {
    opacity: 0.25;
  }
}
.newslist .detail_date {
  display: flex;
  align-items: center;
  margin: 0 0 0.25rem 0;
  text-transform: uppercase;
  font-family: Helvetica, sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}
.newslist .delimiter {
  display: none;
}
.newslist .link {
  width: 100% !important;
  margin-top: 1.5em !important;
}
.newslist .link a {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #194894;
  color: #194894;
  text-align: center;
  font-family: Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.newslist .link span {
  transition: 400ms ease-in-out;
}
@media (min-width: 576px) {
  .newslist--full-width .latestnews-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.newslist--full-details .latestnews-item .newsinfo {
  padding: 1.5rem 1rem !important;
}
.newslist--full-details .latestnews-item ul,
.newslist--full-details .latestnews-item p {
  margin-bottom: 1rem !important;
}

/* News Page
  ================================================== */
.news-item-article .main-content {
  flex-direction: column;
  padding: 0;
}
.news-item-article .published {
  margin: 0;
  font-family: Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.news-item-article .com-content-image,
.news-item-article .item-page {
  max-width: 960px;
  width: 100%;
  margin: auto;
}
.news-item-article .com-content-image {
  margin-bottom: 0;
}
.news-item-article .com-content-image figure {
  position: relative;
  margin: 0;
  background-color: #ffffff;
}
.news-item-article .com-content-image img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}
.news-item-article .item-page {
  padding: 3em;
  background-color: #ffffff;
}
.news-item-article .item-page * {
  color: #194894;
}
.news-item-article .article-info.muted {
  display: block;
  color: #194894;
  margin-bottom: 2em;
}

.sharethis-inline-share-buttons {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.article-info.muted {
  display: none;
}

/* Footer
================================================== */
footer {
  background-color: #7795cb;
  padding-top: 3em;
  margin-top: 3em;
  overflow: hidden;
}
footer .row {
  max-width: 90%;
}
footer ul {
  list-style: none;
  margin: 0;
}
footer .mod-menu__sub {
  display: none;
}
footer .footer--top .custom {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0.8em;
}
@media (max-width: 575px) {
  footer .footer--top .custom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
footer .footer--top .custom > div {
  width: 23%;
}
@media (max-width: 1023px) {
  footer .footer--top .custom > div {
    width: 45%;
  }
}
@media (max-width: 575px) {
  footer .footer--top .custom > div {
    width: 100%;
  }
}
footer .footer--top p {
  margin-right: 1.5em;
}
@media (max-width: 575px) {
  footer .footer--top p:first-child {
    text-align: center;
  }
}
footer .footer--top p:last-child {
  margin-right: 0;
}
@media (max-width: 1023px) {
  footer .footer--top p {
    margin-bottom: 3em;
  }
}
@media (max-width: 575px) {
  footer .footer--top p {
    margin-right: 0;
  }
}
footer .footer--top p.buttons {
  display: flex;
  flex-direction: column;
}
footer .footer--top p.buttons a {
  margin: 0.5em auto;
  width: 100%;
  text-align: center;
}
footer .footer--top p.address {
  color: #ffffff;
  line-height: 2;
}
@media (max-width: 479px) {
  footer .footer--top p.address {
    text-align: center;
  }
}
footer .footer--top p.address a {
  color: #ffffff;
  text-decoration: underline;
}
footer .footer--bottom {
  width: 100%;
  position: relative;
  text-align: center;
  padding: 1em 0;
  z-index: 1;
}
@media (max-width: 479px) {
  footer .footer--bottom {
    z-index: 101;
  }
}
footer .footer--bottom p {
  color: #ffffff;
  margin-bottom: 0;
}
@media (max-width: 479px) {
  footer .footer--bottom p {
    font-size: 1em;
    margin-top: 1em;
  }
}
footer .footer--bottom:before {
  content: "";
  position: absolute;
  top: 0;
  left: -20%;
  background-color: #f5b04f;
  width: 140%;
  height: 100%;
  z-index: -1;
}
footer .footer--bottom .custom {
  max-width: 31em;
  margin: 0 auto;
}
@media (max-width: 479px) {
  footer .footerMenuModule {
    width: 100%;
  }
}
@media (max-width: 479px) {
  footer .nav {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
}
footer .nav a {
  color: #ffffff;
}
footer .nav a:hover {
  color: #7795cb;
}
footer .footer-flex {
  display: flex;
  width: 100%;
  align-items: center;
  justify-items: center;
  text-align: right;
}
@media (max-width: 479px) {
  footer .footer-flex {
    display: block;
    text-align: center;
  }
}
footer .footer-flex img {
  width: 220px;
  margin-left: 0.8rem;
}
@media (max-width: 479px) {
  footer .footer-flex img {
    margin: 0;
    vertical-align: middle;
  }
}
footer .fa-caret-down {
  display: none;
}