/* Header
================================================== */
header {
  position: relative;
  z-index: 1;

  .row {
    max-width: 100%;

    .column {
      padding-left: 0;
      padding-right: 0;
      background-color: $secondary-colour;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

      .header__topBanner {
        width: 100%;
        p {
          margin: 0;
        }
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
  .header__additionalItems {
    .button--calendar {
      @include bp_max(smedium) {
        display: none;
      }
    }

    p {
      margin: 0;
    }

    .language {
      transition: all 400ms ease-in-out;

      &:hover {
        color: $primary-colour;
        transition: all 400ms ease-in-out;

        svg path {
          fill: $primary-colour;
          transition: all 400ms ease-in-out;
        }
      }
    }

    .language svg path {
      transition: all 400ms ease-in-out;
    }
  }
}

.banner.active {
  z-index: -1;
}

#banner {
  position: relative;
  min-height: 20em;
  height: 400px;
  overflow: hidden;
  z-index: 0;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
      content: "";
      display: table;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.4;
    }
  }

  .overlay__items {
    position: absolute;
    bottom: 1em;
    left: 2em;
    z-index: 2;

    @include bp_max(smedium) {
      left: 1em;
    }

    .button {
      background: $accent-colour;

      &:hover {
        background: $primary-colour;
      }

      @include bp_max(smedium) {
        width: 48%;
        text-align: center;
        font-size: 1em;
        padding: 1em;
      }

      &:last-child {
        @include bp_max(smedium) {
          width: 97%;
        }
      }
    }
  }

  .overlay__items--logo {
    position: absolute;
    bottom: 7em;
    left: 2em;
    z-index: 2;
    width: 5em;
    height: 5em;

    @include bp_max(smedium) {
      bottom: 10em;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  /*img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    object-position: 100% 150%;
  }*/

  p {
    margin: 0;
  }
}

//slider
