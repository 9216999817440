/* Navigation
================================================== */

header {
  z-index: 2;
}
.nav--main {
  .row {
    position: unset;
  }

  .header__nav {
    width: 100%;
    background-color: $primary-colour;
    padding: 0 1em;

    @include bp_max(xxlarge) {
      display: none;
      margin-top: 0;
      //overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 99;
      max-height: 0;
      width: 100%;
      background: $secondary-colour;
      transition: max-height 0.6s ease-in-out;
      -webkit-transition: max-height 0.6s ease-in-out;
      -moz-transition: max-height 0.6s ease-in-out;
      -ms-transition: max-height 0.6s ease-in-out;
      -o-transition: max-height 0.6s ease-in-out;
    }

    &.active {
      max-height: 100rem;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      position: fixed;
      overflow: hidden;

      li {
        text-align: center;

        a {
          @include bp_max(large) {
            padding-top: 1em;
            padding-bottom: 1em;
            width: 100%;
            max-width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    @include bp_max(large) {
      &.active {
        .nav {
          display: block;
        }
        a {
          display: block;
          padding: 0.6em;
          margin: 0 auto;
        }
        li,
        ul.active {
          width: 100%;
          //height: 3.5em;
          float: left;
          display: block;
          font-size: 1em !important;
        }
        ul.active {
          display: block;
          position: static;
          top: auto;
        }
      }
    }
  }

  .header__additional {
    background-color: $secondary-colour;
    width: 100%;
    padding: 0.7em;

    @include bp_max(xxlarge) {
      position: relative;
    }

    @include bp_max(smedium) {
      width: calc(100% - 4em);
      background-color: transparent;
    }

    .header__additionalItems {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      margin-right: 1em;

      @include bp_max(smedium) {
        justify-content: flex-end;
      }

      a {
        margin-bottom: 0;
      }
    }

    .language {
      color: $white;
      text-transform: uppercase;
      cursor: pointer;
      margin-right: 1em;

      svg {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        transform: translateY(0.2em) rotate(-90deg);
        transition: all 0.5s ease-in;
      }

      &.open {
        svg {
          transform: translateY(0.2em) rotate(90deg);
        }
      }
    }
  }

  #google_translate_element {
    position: absolute;
    top: 100%;
    //right: -14em;
    right: 11em;
    background: $secondary-colour;
    padding: 1em;
    transition: right 1s ease-in;
    display: none;

    @include bp_max(smedium) {
      right: 0;
    }

    //&.open {
    //   right: 0;
    // }
  }

  .nav {
    width: 100%;
    margin: 0;
    display: none;

    @media only screen and (min-width: 1650px) {
      max-width: 125em;
    }

    &.active {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include bp(xxlarge) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    @include bp_max(xxlarge) {
      margin-top: 3em;
    }

    li {
      position: relative;
      text-align: center;
      list-style: none;
      left: 0;
      width: auto;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      &.parent {
        position: relative;

        @include bp_max(smedium) {
          padding-top: 1em;
          padding-bottom: 1em;
        }

        a {
          @include bp(large) {
            padding-right: 23px;
          }
        }

        & > a {
          &:after {
            content: "";
            display: table;
            width: 0;
            height: 0;
            position: absolute;
            right: 6px;
            top: 1rem;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #ffffff;
            -webkit-transition: border-top 400ms ease-in-out;
            -moz-transition: border-top 400ms ease-in-out;
            -ms-transition: border-top 400ms ease-in-out;
            -o-transition: border-top 400ms ease-in-out;

            @include bp(large) {
              top: 43%;
            }

            @include bp_max(smedium) {
              right: 9em;
            }
          }
        }

        & > a:hover,
        &.active > a,
        &.current > a {
          &:after {
            border-top: 6px solid $primary-colour;
          }
        }

        li {
          a {
            &:hover {
              @include bp(large) {
                background-color: $tertiary-colour;
              }
            }
          }
          &.current {
            a {
              @include bp(large) {
                background-color: $tertiary-colour;
              }
            }
          }
        }
      }
    }
    a {
      display: block;
      padding: 17px 11px;
      color: $white;
      font-size: 0.8em;
      text-decoration: none;
      line-height: 120%;
      font-family: $secondary-black;
      text-transform: uppercase;
      max-width: 12em;

      @include bp(large) {
        padding: 17px 6px;
      }
      @include bp(xxlarge) {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 17px 11px;
      }

      @media only screen and (min-width: 1350px) {
        font-size: 1em;
      }
    }
    a:hover,
    .current > a,
    .active > a {
      color: $primary-colour;
    }
    /*i {
      margin-left: 5px;
      display: none;
    }*/

    .nav-child {
      @include bp_max(large) {
        width: 120%;
        margin-left: -10%;
      }

      a {
        font-size: 0.8em;
        transition: all 0.2s ease-in;
        width: 100%;
        text-align: left;

        @include bp_max(smedium) {
          height: 100%;
          display: flex !important;
          align-items: center;
          justify-content: center;
          font-size: 1em;
          float: none;
          width: 100%;
          text-align: center;
        }

        &:hover {
          color: $white;
          background: $tertiary-colour;
          width: 100%;
        }
      }

      li.current.active a {
        color: $white;
      }

      li {
        background: $primary-colour;
        z-index: 99;

        @include bp_max(smedium) {
          height: 4em;
        }
      }
    }

    ul {
      display: none;
      width: 100%;
      float: left;
      position: static;
      margin: 0;
      font-size: 0.8em;

      @include bp(large) {
        position: absolute;
        min-width: 150px;
        width: auto;
        top: 100%;
        left: 0;
        z-index: 9;
        font-size: 1em;
        background-color: $primary-colour;
      }
      &.active {
        @include bp_max(large) {
          display: block;
        }
      }
      li {
        width: 100%;
      }
    }
    li:hover > ul {
      @include bp(large) {
        display: block;
      }
    }
    & > * {
      overflow: visible;
    }

    @include bp_max(smedium) {
      &.active a {
        padding-left: 0;
      }
    }
  }
}

.menu-link {
  height: 2.3em;
  width: 2.3em;
  position: relative;
  z-index: 100;
  display: block;
  cursor: pointer;
  margin: 10px 0;
  background-color: transparent;
  padding: 0;
  margin-left: 1em;

  &:hover {
    background: none;
  }

  span,
  span:before,
  span:after {
    width: 35px;
    height: 3px;
    position: absolute;
    top: 17px;
    display: block;
    content: "";
    background: $white;
    @include border-radius(1px);
    @include transition(all 500ms ease-in-out);
  }

  span {
    &:before {
      top: -10px;
    }
    &:after {
      top: auto;
      bottom: -10px;
    }
  }

  &.active {
    position: fixed;
    top: 1em;

    span {
      background: transparent;
      &:before,
      &:after {
        top: 0;
        background: $white;
      }
      &:before {
        @include rotate(45);
      }
      &:after {
        @include rotate(-45);
      }
    }
  }

  @include bp(xxlarge) {
    display: none;
  }
}
