/* Footer
================================================== */

footer {
  background-color: $accent-colour;
  padding-top: 3em;
  margin-top: 3em;
  overflow: hidden;

  .row {
    max-width: 90%;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  .mod-menu__sub {
    display: none;
  }

  .footer--top {
    .custom {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 0.8em;
      @include bp_max(medium) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      & > div {
        width: 23%;

        @include bp_max(xlarge) {
          width: 45%;
        }

        @include bp_max(medium) {
          width: 100%;
        }
      }
    }

    p {
      margin-right: 1.5em;

      &:first-child {
        @include bp_max(medium) {
          text-align: center;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      @include bp_max(xlarge) {
        margin-bottom: 3em;
      }

      @include bp_max(medium) {
        margin-right: 0;
      }

      &.buttons {
        display: flex;
        flex-direction: column;

        a {
          margin: 0.5em auto;
          width: 100%;
          text-align: center;
        }
      }

      &.address {
        color: $white;
        line-height: 2;

        @include bp_max(smedium) {
          text-align: center;
        }

        a {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }

  .footer--bottom {
    width: 100%;
    position: relative;
    text-align: center;
    padding: 1em 0;
    z-index: 1;
    //height: 4em;

    @include bp_max(smedium) {
      //height: 7em;
      z-index: 101;
    }

    p {
      color: $white;
      margin-bottom: 0;

      @include bp_max(smedium) {
        font-size: 1em;
        margin-top: 1em;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -20%;
      background-color: $secondary-colour;
      width: 140%;
      height: 100%;
      z-index: -1;
    }

    .custom {
      max-width: 31em;
      margin: 0 auto;
    }
  }

  .footerMenuModule {
    @include bp_max(smedium) {
      width: 100%;
    }
  }

  .nav {
    @include bp_max(smedium) {
      width: 100%;
      text-align: center;
      margin-left: 0;
    }

    a {
      color: $white;

      &:hover {
        color: $tertiary-colour;
      }
    }
  }

  .footer-flex {
    display: flex;
    width: 100%;
    align-items: center;
    justify-items: center;
    text-align: right;

    @include bp_max(smedium) {
      display: block;
      text-align: center;
    }

    img {
      width: 220px;
      margin-left: 0.8rem;
      @include bp_max(smedium) {
        margin: 0;
        vertical-align: middle;
      }
    }
  }

  .fa-caret-down {
    display: none;
  }
}
