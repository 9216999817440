.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  background: $primary-colour;
  border: 0;
  color: $white;
  display: inline-block;
  font-size: 1.1em;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20px;
  line-height: normal;
  padding: 0.7em 1.5em;
  font-family: $primary-font;
  text-transform: uppercase;
  @include transition($global-transition);

  &:hover {
    color: $white;
    background: lighten($primary-colour, 15%);
    border: 0;
  }
  &:active {
    border: 0;
  }
}
.button--secondary {
  background: none;
  border: 2px solid $primary-colour;
  color: $primary-colour;

  &:hover {
    background: $primary-colour;
    border: 2px solid $primary-colour;
    color: $white;
  }
}
.button--calendar {
  padding: 0.5em 0.8em 0.5em 2em;
  background-image: url("/images/misc/calendar.gif");
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: 0.5em 0.55em;

  &:hover {
    background-image: url("/images/misc/calendar.gif");
    background-repeat: no-repeat;
    background-size: 1em;
    background-position: 0.5em 0.55em;
    background-color: lighten($primary-colour, 15%);
  }
}

footer .button--calendar {
  padding: 0.7em 1.5em 0.7em 2em;
  border: 2px solid $primary-colour;
  background-position: 0.5em 0.65em;

  &:hover {
    border: 2px solid lighten($primary-colour, 15%);
    background-position: 0.5em 0.65em;
  }
}

.button.full-width,
button.full-width,
input[type="submit"].full-width,
input[type="reset"].full-width,
input[type="button"].full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
