#home {
  main {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 3em;

    @include bp(xxlarge) {
      margin-top: 6em;
      margin-bottom: 9em;
    }

    @include bp_max(medium) {
      width: 100% !important;
    }

    @include bp_max(smedium) {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 98%;
      height: 270%;
      background-image: url(/images/venue3.jpg);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: right top;
      z-index: -1;

      @include bp(xxlarge) {
        width: 1550px;
        height: 176%;
      }

      @include bp_max(smedium) {
        top: -1em;
      }
    }

    .main-content {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 26%;
        height: 100%;
        top: 3em;
        left: -2em;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
        z-index: 0;

        @include bp_max(smedium) {
          display: none;
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 1.5%;
        height: 115%;
        top: 3em;
        left: -2em;
        border-top: 1px solid #000;
      }

      &.animated {
        &:before {
          width: 26%;
          height: 115%;
          //animation: border-before 1s linear forwards;
          animation-delay: 2s;
          border-left: 1px solid #000;
          border-bottom: 1px solid #000;

          @include bp_max(large) {
            height: 100%;
          }
        }

        &:after {
          width: 3%;
          height: 115%;
          animation: border-after 1s linear forwards;
          animation-delay: 2s;
          border-top: 1px solid #000;
        }
      }
    }

    h1 {
      color: $tertiary-colour;

      @include bp_max(smedium) {
        font-size: 2em;
      }
    }

    .item-page > div {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;

      .topSection--left {
        width: 60%;
        position: relative;
        padding-right: 2em;

        @include bp_max(xlarge) {
          width: 90%;
        }

        @include bp_max(smedium) {
          width: 100%;
        }
      }

      .topSection--right {
        width: 40%;
        align-items: flex-end;
        justify-content: flex-start;
        display: flex;

        @include bp_max(xlarge) {
          width: 100%;
          z-index: 90;
        }

        .button {
          @include bp_max(smedium) {
            margin-top: 2em;
          }
        }
      }
    }
  }

  .sidebar {
    padding-top: 10em;

    @include bp_max(medium) {
      width: 100% !important;
      padding-top: 8em;
    }

    iframe {
      width: 100%;
      height: auto;
      min-height: 18em;
    }
  }

  .experienceSlider__section {
    height: 25em;

    @include bp(xxlarge) {
      height: 35em;
    }
  }
}

.featured-text {
  color: $accent-colour;
  font-size: 2.14em;
  font-family: $secondary-black;
  line-height: 1.2;
}

//tab section

.tabs__section {
  margin-bottom: 3em;
  margin-top: 3em;

  @include bp_max(smedium) {
    margin-top: 1.5em;
    margin-bottom: 4.5em;
  }
}

.tabs__sections {
  min-height: 30em;
  height: auto;

  .section {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2em;
    display: none;
    //position: absolute;
    //left:0;
    //top: 4em;
    //opacity: 0;
    //visibility: hidden;

    // @include bp_max(smedium) {
    //top: 12em;
    //}

    &.active {
      display: flex;
      //opacity: 1;
      //transition: opacity 1s linear;
      //visibility: visible;
    }

    .button {
      margin-top: 1em;
    }

    .section__content {
      width: 60%;
      padding-right: 5em;

      @include bp_max(xlarge) {
        width: 100%;
        margin-bottom: 3em;
      }

      @include bp_max(smedium) {
        padding-right: 0;
      }
    }

    .section__image {
      width: 40%;
      max-width: 32.143em;
      position: relative;

      @include bp_max(xlarge) {
        width: 100%;
      }

      .section__image--wrap {
        position: relative;
        height: 25em;
        overflow: hidden;
        width: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        max-width: inherit;
        min-width: 100%;
        height: 100%;
      }

      &:after {
        content: "";
        position: absolute;
        top: 20%;
        right: -10%;
        width: 100%;
        height: 90%;
        border-top: 1px solid #000;
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        z-index: -1;

        // FIX
        @include bp_max(xxlarge) {
          height: 70%;
        }

        @include bp_max(smedium) {
          display: none;
        }
      }
    }
  }
}

.tabs_buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid $primary-colour;

  .tab {
    color: #bdc3c7;
    width: auto;

    @include bp_max(smedium) {
      width: 100%;
    }
  }

  .tab.active {
    color: $primary-colour;
  }

  h2 {
    font-family: $secondary-font;
    cursor: pointer;
  }
}

// Experience Slider Section

.experienceSlider__section {
  position: relative;
  padding-top: 3em;
  padding-bottom: 3em;

  @include bp_max(smedium) {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  &.animated {
    h2 {
      &:before {
        width: 22%;

        @include bp_max(xxlarge) {
          width: 16%;
        }

        @include bp_max(large) {
          width: 4%;
          left: -0.5em;
        }

        @include bp_max(small) {
          left: -0.5em;
          width: 5%;
          top: 2.2em;
        }
      }

      &:after {
        width: 22%;

        @include bp_max(xxlarge) {
          width: 16%;
        }

        @include bp_max(xlarge) {
          width: 13%;
        }

        @include bp_max(large) {
          width: 4%;
          right: -0.5em;
        }

        @include bp_max(small) {
          right: -0.5em;
          width: 5%;
          top: 2.3em;
        }
      }
    }
  }

  h2 {
    margin: 0 auto 1em auto;
    max-width: 20em;
    text-align: center;
  }

  .news {
    padding: 0 1em !important;
  }

  .newsintro {
    text-transform: uppercase;
    font-size: 1.286em;
    line-height: 1.3;
    margin-top: 1.5em;
    white-space: normal;
    text-align: center !important;
  }

  .cycle-carousel-wrap {
    .latestnews-item {
      img {
        object-fit: cover;
      }
    }
  }
}

//override slider settings fo tit image width

.newshead .picture .innerpicture {
  left: unset !important;
  width: unset !important;
  position: relative;
}

#prev svg,
#prevG svg,
#next svg,
#nextG svg {
  width: 2em;
  height: 2em;
  position: absolute;
  top: -2em;
}

#next svg,
#prev svg {
  top: -2em;
}

#prev svg,
#prevG svg {
  transform: rotate(180deg);
}

.center {
  display: flex;
  justify-content: space-between;
}

.slider__controls {
  position: absolute;
  width: 103%;
  margin-left: -3em;
  top: 18em;

  @include bp_max(smedium) {
    width: 95%;
    margin-left: -1.5em;
  }
}

#post-show-g {
  position: relative;

  .slider__controls {
    top: 60%;
  }
}

// Countdown section

.fullwidth-section {
  position: relative;

  .row {
    max-width: 100%;
    width: 100%;

    .column,
    .columns {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.countdown__section {
  width: 100%;
  position: relative;
  height: 25em;
  overflow: hidden;
  margin: 3em 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $accent-colour;
    z-index: -1;
  }

  h2 {
    color: $white;
    text-align: center;
    position: absolute;
    top: 1.5em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
  }

  p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    min-width: 100%;
    object-fit: cover;
    max-width: unset;
    height: 100%;
    z-index: 0;
  }

  .countdownModule {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 6em;
    z-index: 5;

    h3 {
      display: none;
    }
  }
}

.counter {
  position: absolute;
  width: 100%;
  height: 20em;
  bottom: 2em;
  left: 0;

  @include bp_max(small) {
    bottom: -4em;
  }
}
.soon-text {
  color: $white;
}

//keydates section

.keyDates__section {
  padding: 3em 0em 6em 0em;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @include bp_max(smedium) {
    padding: 2em 0em 2em 0em;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 74%;
    border: 1px solid #000;
    top: 3em;
    left: 0;
    z-index: -1;

    @include bp_max(smedium) {
      top: 2em;
      height: 95%;
    }
  }

  h2 {
    margin: 0 auto 2em auto;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;

    @include bp_max(large) {
      margin: 0 auto 1em auto;
    }

    &:after {
      content: "";
      position: absolute;
      width: 30%;
      height: 1px;
      background: #fff;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 1 !important;

      @include bp_max(smedium) {
        opacity: 1;
      }
    }

    &:after {
      right: 0;
      left: unset;
    }
  }

  .datesSection {
    width: 30%;
    text-align: center;

    @include bp_max(large) {
      width: 50%;

      &:last-child {
        width: 100%;
      }
    }

    @include bp_max(smedium) {
      width: 100%;
    }

    h3 {
      color: $accent-colour;

      svg {
        width: 1.2em;
        height: 1.2em;
        margin-right: 1em;
        transform: translateY(0.2em);
      }
    }

    p {
      text-transform: uppercase;
    }
  }

  &.animated {
    h2 {
      &:before,
      &:after {
        width: 30%;

        @include bp_max(smedium) {
          width: 15%;
        }
      }
    }
  }
}

//tweets & Floorplans

.feedSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4em;
  margin-bottom: 3em;

  @include bp_max(smedium) {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .feedSection__tweeter {
    width: 57%;

    @include bp_max(large) {
      width: 100%;
      text-align: center;
      margin-bottom: 3em;
    }

    .feedSection__title {
      &:before,
      &:after {
        width: 28%;

        @include bp_max(smedium) {
          width: 5%;
        }
      }
    }
  }

  .feedSection__additional {
    //width: 38%;
    width: 100%;

    @include bp_max(large) {
      width: 100%;
      margin-top: 3em;
      margin-bottom: 3em;
    }

    @include bp_max(smedium) {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .feedSection__title {
      &:before,
      &:after {
        width: 22%;

        @include bp_max(smedium) {
          width: 10%;
        }
      }
    }

    .feedSection__imageWrap {
      width: 100%;
      min-height: 22em;
      height: auto;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (min-width: 1500px) {
        min-height: 30em;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }

      img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        height: 100%;
        max-width: unset;
      }

      a {
        z-index: 2;
        color: $white;
        border-color: $white;
        margin-bottom: 0;

        &:hover {
          color: $primary-colour;
          background: $white;
        }
      }
    }
  }

  .feedSection__title {
    position: relative;
    width: 100%;
    text-align: center;

    &:before,
    &:after {
      position: absolute;
      top: 37%;
      content: "";
      height: 1px;
      background: #000;
      width: 50%;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    h2 {
      width: auto;
      background: $white;
      z-index: 1;
    }
  }
}

//Other events section

.othetEvents__section {
  position: relative;
  margin-top: 3em;
  margin-bottom: 3em;
  clear: both;
  display: block;
  float: left;
  width: 100%;

  @include bp_max(smedium) {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  @include bp_max(small) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 1px solid #000;
    top: 1em;
    left: 0;
    z-index: -1;
  }

  h2 {
    margin: 0 auto 2em auto;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;

    @include bp_max(smedium) {
      margin: 0 auto 1em auto;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 12em;
      height: 1em;
      transform: translateX(-50%);
      background-color: #fff;
      z-index: -1;

      @include bp_max(smedium) {
        width: 8em;
      }
    }
  }

  p {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @include bp_max(smedium) {
      justify-content: space-around;
    }

    a {
      width: 15%;
      max-width: 15%;
      height: auto;

      &.reduced {
        width: 15%;

        @include bp_max(medium) {
          width: 45%;
        }
      }

      /*&:nth-child(2) {
          width: 8%;
          max-width: 8%;
          
          @include bp(medium) {
            width: 30%;
            max-width: 30%;
            margin-bottom: 3em;
          }
        }
        
        &:nth-child(1) {
          width: 20%;
          max-width: 20%;
          
          @include bp(medium) {
            width: 30%;
            max-width: 30%;
            margin-bottom: 3em;
          }
        }*/

      @include bp_max(medium) {
        width: 45%;
        max-width: 45%;
        margin-bottom: 3em;
      }
    }
  }
}

// General Styles

main {
  @include bp_max(smedium) {
    z-index: 0;
  }
}

.mobile-top {
  @include bp_max(medium) {
    margin-top: 2em;
  }
}

.mobile-no-bottom {
  @include bp_max(smedium) {
    margin-bottom: 0;
  }
}

.main-content {
  margin: 3em 0 0;
}

.row-narrow-mobile {
  @include bp_max(smedium) {
    max-width: 80%;
  }
}

.mobile-menu-link {
  @include bp(large) {
    display: none !important;
  }
}

.contentBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contentBox__content,
.contentBox__image {
  width: 48%;
  overflow: hidden;

  @include bp_max(large) {
    width: 100%;
  }

  iframe {
    width: 100%;
  }
}

.contentBox__content {
  padding-right: 2em;

  @include bp_max(smedium) {
    padding-right: 0;
  }
}

#contact {
  .contentBox {
    text-align: center;
  }
}

.contentBox {
  &:nth-child(2) {
    .contentBox__image {
      @include bp_max(smedium) {
        margin-bottom: 2em;
      }
    }
  }
}

.sticky {
  position: sticky;
  top: 1em;

  @include bp_max(large) {
    position: unset;
  }
}

.social--module {
  position: fixed;
  top: 60vh;
  right: 0;

  @include bp_max(smedium) {
    bottom: 0;
    top: unset;
    right: 0;
    width: 100%;
    z-index: 100;
  }

  .social {
    width: 4em;
    height: 13em;
    background: #f3f4f4;
    display: flex;
    flex-direction: column;
    padding-top: 1.5em;

    @include bp_max(smedium) {
      width: 100%;
      height: 4em;
      flex-direction: row;
      padding-top: 1em;
    }

    &__el {
      width: 4em;
      height: 3em;
      text-align: center;

      @include bp_max(smedium) {
        width: 25%;
        height: 4em;
      }
    }

    svg {
      width: 1.5em;
      height: 1.5em;

      @include bp_max(smedium) {
        width: 2em;
        height: 2em;
      }
    }
  }
}

h2,
.feedSection__title,
.othetEvents__section {
  &:before,
  &:after {
    @include bp_max(smedium) {
      opacity: 0;
    }
  }
}

//sponsorship page

.sponsorship-image {
  border-bottom: 1px solid #787878;
}

#sponsor {
  .featured-text {
    margin: 1em 0;
  }

  .button {
    margin-right: 1em;
  }
}

// partners section

.partners__module {
  margin: 3em 0;
  position: relative;

  @include bp_max(smedium) {
    margin: 1.5em 0;
  }

  ul {
    list-style: none;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    li {
      width: 14em;
      padding: 1.2em;

      img {
        vertical-align: middle;
        max-width: 11em;
        width: 100%;
      }
    }
  }

  /*&:before {
        content: "";
        position: absolute;
        width: 100%;
      	height: 1px;
        border-top: 1px solid #000;
        top: 1em;
        left: 0;
        z-index: -1;
     
     @media $mobile-wide-max {
       display: none;
     }
      }*/

  h2 {
    margin: 0 auto 2em auto;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;

    @include bp_max(smedium) {
      margin: 0 auto 1em auto;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 14em;
      height: 1em;
      transform: translateX(-50%);
      background-color: #fff;
      z-index: -1;

      @include bp_max(smedium) {
        width: 8em;
      }
    }
  }

  .title-wrap:before {
    top: 2em;
  }
}

.ba-gallery-grid {
  min-height: 68em;

  &:before {
    content: "";
    display: table;
    clear: both;
  }
}

// program section

.tabs__sections--program {
  h3 {
    margin-top: 2em;

    @include bp_max(smedium) {
      margin-top: 1em;
    }
  }

  p {
    width: 100%;
    clear: both;
  }

  .name {
    color: $primary-colour;
  }
}

#register {
  .item-page {
    > div {
      min-height: 35em;
    }
  }
}

.CBiframe {
  height: 38em !important;
  min-height: 38em !important;
}

// Lined Titles

.title-wrap {
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 1px solid #000;
    top: 1em;
    left: 0;
    z-index: -1;

    @include bp_max(smedium) {
      display: none;
    }
  }
}

.experienceSlider__section {
  &:before {
    content: "";
    position: absolute;
    width: 15%;
    height: 1px;
    border-top: 1px solid #000;
    top: 4em;
    left: 0;
    z-index: -1;

    @include bp(xxlarge) {
      width: 24%;
    }

    @media only screen and (min-width: 1000px) and (max-width: 1200px) {
      width: 10%;
    }

    @media only screen and (min-width: 960px) and (max-width: 1000px) {
      width: 7%;
    }

    @include bp(medium) {
      width: 7%;
    }

    @include bp_max(smedium) {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 15%;
    height: 1px;
    border-top: 1px solid #000;
    top: 4em;
    right: 0;
    z-index: -1;

    @include bp_max(smedium) {
      display: none;
    }

    @include bp(xxlarge) {
      width: 24%;
    }

    @media only screen and (min-width: 1000px) and (max-width: 1200px) {
      width: 10%;
    }

    @media only screen and (min-width: 960px) and (max-width: 1000px) {
      width: 7%;
    }

    @include bp(medium) {
      width: 7%;
    }

    @include bp_max(smedium) {
      display: none;
    }
  }

  h2 {
    margin: 0 auto 2em auto;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 1;

    @include bp_max(large) {
      max-width: 80%;
    }

    @include bp_max(smedium) {
      margin: 0 auto 1em auto;
      max-width: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 19em;
      height: 1em;
      transform: translateX(-50%);
      z-index: -1;

      @include bp_max(smedium) {
        width: 8em;
      }
    }
  }
}

.experienceSlider__section.guangzhou {
  /*&:before,
  &:after {
  	 width: 15%;
    
    @include bp(xxlarge) {
      width: 8%;
    }
    
    @include bp(large) {
      width: 8%;
    }
  }
  
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    border-top: 1px solid #000;
    top: 4em;
    left: unset;
    right: 0;
    z-index: -1;
  }
  
  h2:after {
    display: none;
  }*/

  h2:after {
    width: 21em;
  }
}

.keyDates__section {
  .title-wrap:before {
    opacity: 0;

    @include bp_max(smedium) {
      display: block;
    }
  }
  h2.line-title {
    margin: -0.5em auto 2em auto;

    @include bp_max(smedium) {
      margin: -2em auto 2em auto;
    }
  }
  h2.line-title:after {
    width: 8em;
  }

  &.other-title {
    &:after {
      width: 8em !important;
    }
  }
}

h2.line-title {
  margin: 0 auto 2em auto;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;

  @include bp_max(smedium) {
    margin: 0 auto 1em auto;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 12em;
    height: 1em;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: -1;

    @include bp_max(smedium) {
      width: 8em;
    }
  }

  &.associates-title {
    &:after {
      width: 13em;
    }
  }

  &.other-title {
    &:after {
      width: 13em;
    }
  }
}

// Secondary page titles

.secondary-page {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;

  @include bp_max(large) {
    flex-direction: column-reverse;
  }

  .title,
  .cta {
    @include bp_max(large) {
      width: 100% !important;
    }
  }

  .title {
    width: 70%;
  }

  .cta {
    width: 30%;
    align-self: flex-start;

    a {
      float: right;

      @include bp_max(large) {
        float: none;
      }
    }
  }
}

.internal {
  .main-content {
    i.fa.fa-caret-down {
      display: none;
    }
  }
}

//masonry gallery

* {
  box-sizing: border-box;
}

/* force scrollbar */
html {
  overflow-y: scroll;
}

/* ---- grid ---- */

.grid {
  background: none;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 24.5%;
  margin-bottom: 0.6%;

  @include bp_max(medium) {
    width: 49%;
  }

  @include bp_max(small) {
    width: 100%;
  }
}

.gutter-sizer {
  width: 0.6%;

  @include bp_max(small) {
    width: 0;
  }
}

.grid-item {
  float: left;
}

//.grid-item img {
//  display: block;
//  max-width: 100%;
//}

// Hide Loading message on Exhibitor lists

.loading-message {
  animation: fadeOut 5s forwards;
}

// post show sliders

.lnee.newslist {
  @include bp(large) {
    height: 15em;

    @include bp(xxlarge) {
      height: 20em;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+
  #post-show-g {
    height: 35em;
  }

  .feedSection .feedSection__additional .feedSection__imageWrap a {
    margin-top: 35%;
  }
}
